import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router'; //eslint-disable-line

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usuario: JSON.parse(sessionStorage.session || '{}'),
    loading: false,
    printing: false,
    message: null,
    active: false,
    timeout: 3000,
  },
  getters: {
    usuario: (state) => state.usuario,
    loading: (state) => state.loading,
    printing: (state) => state.printing,
    message: (state) => state.message,
    active: (state) => state.active,
    timeout: (state) => state.timeout,
  },
  mutations: {
    SET_USER(state, payload) {
      sessionStorage.session = JSON.stringify(payload);
      state.usuario = payload;
    },
    LOGOUT(state) {
      sessionStorage.removeItem('session');
      state.usuario = {};
    },
    UPDATE_LOADING(state, loading) {
      state.loading = loading;
    },
    UPDATE_PRINTING(state, printing) {
      state.printing = printing;
    },
    SHOW_MESSAGE(state, message) {
      state.message = message;
      state.active = true;
      state.timeout = 3000;
    },
    CLEAR_MESSAGE(state) {
      state.message = null;
      state.active = false;
    },
  },
  actions: {
    userLogout({ commit }) {
      commit('LOGOUT');
      router.push('/login');
    },
    userLogin({ commit }, payload) {
      commit('SET_USER', payload);
      router.push('/');
    },
    loaderEnable({ commit }) {
      commit('UPDATE_LOADING', true);
    },
    loaderDisable({ commit }) {
      commit('UPDATE_LOADING', false);
    },
    showMessage({ commit }, message) {
      commit('SHOW_MESSAGE', message);
    },
    clearMessage({ commit }) {
      commit('CLEAR_MESSAGE');
    },
  },
});
