<template>
  <div>
    <v-app-bar
      fixed
      elevation="0"
      height="50"
      color="#2C99B0"
      v-if="usuario.token"
    >
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="hidden-sm-and-up"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="hidden-xs-only text-uppercase">
        <router-link
          class="white--text uppercase"
          style="text-decoration: none"
          to="/"
        >
          <span style="color: #222222">Ione&nbsp;</span>
          <span class="font-weight-light">Doces</span>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only center">
        <v-btn
          v-for="route in routes"
          :to="route.to"
          :key="route.title"
          text
          :color="route.color"
        >{{ route.title }}</v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-btn
        class="hidden-xs-only"
        text
        small
        color="white"
        to="/usuario"
      >
        <v-icon right>{{mdiAccountCog }}</v-icon>
      </v-btn>
      <v-btn
        class="hidden-xs-only"
        text
        small
        color="white"
        @click="userLogout"
      >
        <v-icon right>{{mdiExitToApp}}</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- ------------------------------------------------------------------------------------ -->
    <v-navigation-drawer
      v-model="drawer"
      color="#2C99B0"
      app
      temporary
    >
      <v-list>
        <v-list-item class="px-2">
          <v-img
            contain
            max-height="128"
            src="@/assets/ione-logo.png"
          ></v-img>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{usuario.nome}}
            </v-list-item-title>
            <v-list-item-subtitle>{{usuario.login}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        nav
        dense
      >
        <v-list-item
          link
          to="/"
        >
          <v-list-item-title class="white--text">Pedidos</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          v-for="route in routes"
          :key="route.title"
          :to="route.to"
          :color="route.color"
        >
          <v-list-item-title class="white--text">{{ route.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            class="blue darken-2 white--text"
            to="/usuario"
          >
            Painel Usuário
          </v-btn>
        </div>
        <div class="pa-2">
          <v-btn
            block
            class="blue darken-2 white--text"
            @click="userLogout"
          >
            SAIR
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdiExitToApp, mdiAccountCog } from '@mdi/js';

export default {
  name: 'Nav-bar',
  data() {
    return {
      mdiExitToApp,
      mdiAccountCog,
      drawer: false,
      group: null,
      routes: [
        {
          title: 'Clientes',
          to: '/clientes',
          color: 'white',
        },
        {
          title: 'Doces',
          to: '/doces',
          color: 'white',
        },
        {
          title: 'Produção',
          to: '/producao',
          color: 'white',
        },
      ],
    };
  },
  methods: {
    ...mapActions(['userLogout']),
  },
  computed: {
    ...mapGetters(['usuario']),
  },
};
</script>
