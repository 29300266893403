<template>
  <v-snackbar
      color="blue-grey darken-2"
      v-model="snackbar"
      :bottom="true"
      :timeout="timeout"
    >
      <div class="d-flex justify-space-between align-center">
        <span class="body-1 ml-3">{{message}}</span>
        <v-btn
          text
          small
          fab
          color="white"
          @click="snackbar = false"
        >
          <v-icon>{{mdiCloseThick}}</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { mdiCloseThick } from '@mdi/js';

export default {
  name: 'message-bar',
  data() {
    return {
      snackbar: false,
      mdiCloseThick,
    };
  },
  methods: {
    ...mapActions(['clearMessage']),
  },
  computed: {
    ...mapGetters([
      'message',
      'active',
      'timeout',
    ]),
  },
  watch: {
    active(val) {
      this.snackbar = val;
    },
    snackbar(val) {
      if (val === false) {
        this.clearMessage();
      }
    },
  },
};
</script>
