<template>
  <v-app style="background-color: #D7D7D7;">
    <NavMenu class="d-print-none" />
    <Loader class="d-print-none" />
    <div
      class="d-print-none"
      style="margin-top: 70px;"
    ></div>
    <v-main>
      <router-view />
    </v-main>
    <MessageBar />
  </v-app>
</template>

<script>

import MessageBar from './components/MessageBar.vue';
import NavMenu from './components/NavMenu.vue';
import Loader from './components/Loader.vue';

export default {
  name: 'App',
  components: { NavMenu, Loader, MessageBar },
  data: () => ({
    //
  }),
};
</script>
