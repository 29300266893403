/* eslint no-param-reassign: ["error", { "props": false }] */

import Vue from 'vue';
import axios from 'axios';
import store from '../store';

const config = {
  baseURL: process.env.VUE_APP_URL_API,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const $axios = axios.create(config);

// Add a request interceptor
$axios.interceptors.request.use(
  // request sucesso
  (conf) => {
    store.dispatch('loaderEnable');
    conf.headers.authorization = store.getters.usuario.token;
    return conf;
  },
  // request erro
  (error) => {
    store.dispatch('loaderDisable');
    Promise.reject(error);
  },
);

// Add a response interceptor
$axios.interceptors.response.use(
  // response sucesso
  (response) => {
    store.dispatch('loaderDisable');
    return response;
  },
  // response erro
  (error) => {
    store.dispatch('loaderDisable');
    if (error.response === undefined) store.dispatch('showMessage', String(error));
    else if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch('showMessage', 'Falha de autenticação');
      store.dispatch('userLogout');
    } else if (error.response.data.message) {
      store.dispatch('showMessage', error.response.data.message);
    } else if (error.response.data) {
      store.dispatch('showMessage', String(error.response.data));
    } else {
      store.dispatch('showMessage', String(error.response.statusText));
    }
    Promise.reject(error);
  },
);

Plugin.install = () => {
  Vue.axios = $axios;
  window.axios = $axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return $axios;
      },
    },
    $axios: {
      get() {
        return $axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
