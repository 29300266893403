import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store'; //eslint-disable-line

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'lista-pedidos',
    component: () => import('../views/pedidos/RelatorioPedidos.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/clientes',
    name: 'relatorio-clientes',
    component: () => import('../views/clientes/RelatorioClientes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/clientes/cadastro',
    name: 'cadastro-clientes',
    component: () => import('../views/clientes/CadastroClientes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/clientes/cadastro/:id',
    name: 'cadastro-clientes-edicao',
    component: () => import('../views/clientes/CadastroClientes.vue'),
    meta: { requiresAuth: true },
  }, {
    path: '/doces',
    name: 'relatorio-doces',
    component: () => import('../views/doces/RelatorioDoces.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/doces/cadastro',
    name: 'cadastro-doces',
    component: () => import('../views/doces/CadastroDoce.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/doces/cadastro/:id',
    name: 'cadastro-doces-edicao',
    component: () => import('../views/doces/CadastroDoce.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/pedidos/cadastro',
    name: 'cadastro-pedido',
    component: () => import('../views/pedidos/CadastroPedido.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/pedidos/cadastro/:id',
    name: 'cadastro-pedido-edicao',
    component: () => import('../views/pedidos/CadastroPedido.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/producao',
    name: 'lista-producao',
    component: () => import('../views/producao/ListaProducao.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/usuario',
    name: 'dados-usuario',
    component: () => import('../views/usuario/DadosUsuario.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/usuario/lista',
    name: 'lista-usuarios',
    component: () => import('../views/usuario/ListaUsuarios.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/usuario/cadastro',
    name: 'cadastro-usuario',
    component: () => import('../views/usuario/CadastroUsuario.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/usuario/cadastro/:id',
    name: 'cadastro-usuario-edicao',
    component: () => import('../views/usuario/CadastroUsuario.vue'),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.usuario.token == null) {
      next('/login');
      return;
    }
  }
  next();
});

export default router;
